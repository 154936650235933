import { createApp } from 'vue'
import App from './App.vue'
require('@/assets/sass/main.scss');
import '@fortawesome/fontawesome-free/css/all.min.css'
import VueGoogleMaps from '@fawmi/vue-google-maps'


const app = createApp(App);
app.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_TOKEN,
        libraries: "places"
    },
}).mount('#app')




//createApp(App).mount('#app')
