<template>
  <div class="hero is-fullheight">
    <div>
      <div class="container">
        <div class="columns">
          <div class="column is-half is-offset-one-quarter is-desktop">
            <h1 class="title">
              <img src="images/ol10.png" />
            </h1>
            <form v-if="!success && !error && !showModalFlag">
              <div class="field">
                <p class="control has-icons-left">
                  <span class="select is-large is-fullwidth" :class="{ 'is-danger': v$.form.type.$invalid, 'is-success': !v$.form.type.$invalid }">
                    <select formControlName="type" v-model="v$.form.type.$model" >
                      <option value="" disabled selected>
                        Thema ihres Anliegens
                      </option>
                      <option value="Scheiben eingeworfen">
                        Scheiben eingeworfen
                      </option>
                      <option value="Spayereien festgestellt">
                        Spayereien festgestellt
                      </option>
                      <option value="Beleuchtung geht nicht">
                        Beleuchtung geht nicht
                      </option>
                      <option value="Littering festgestellt">
                        Littering festgestellt
                      </option>
                      <option value="Vandalismus">Vandalismus</option>
                      <option value="Sonstiges" default>Sonstiges</option>
                    </select>
                  </span>
                  <span class="icon is-large is-left">
                    <i class="fas fa-vest-patches"></i>
                  </span>
                </p>
                <p
                  v-for="error of v$.form.type.$errors"
                  :key="error.$uid"
                  class="help is-danger has-text-left"
                >                
                {{ error.$message }}
                </p>
              </div>

              <div class="field">
                <div class="control has-icons-left">
                  <GMapAutocomplete
                    ref="gmapAutocomplete"
                    class="input is-large"
                    :class="{ 'is-danger': v$.form.place.$invalid, 'is-success': !v$.form.place.$invalid }"
                    placeholder="Ort"
                    @place_changed="getAddressData"
                    v-model="v$.form.place.$model"
                    :options="{
                      bounds: { north: 1.4, south: 1.2, east: 104, west: 102 },
                      componentRestrictions: { country: 'ch' },
                      fields: ['address_components', 'geometry', 'name'],
                    }"
                    autocomplete="off"                    
                  >
                  </GMapAutocomplete>

                  <span class="icon is-large is-left">
                    <i class="fas fa-map-location-dot"></i>
                  </span>
                </div>
                <p
                  v-for="error of v$.form.place.$errors"
                  :key="error.$uid"
                  class="help is-danger has-text-left"
                >                
                {{ error.$message }}
                </p>
                
              </div>

              <GMapMap
                  :center="marker.position"
                  :zoom="18"
                  map-type-id="roadmap"
                  style="width: 100%; height: 500px"
                  :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    disableDefaultUi: false,
                  }"
                >
                  <GMapMarker
                    :position="marker.position"
                    :draggable="true"
                    @dragend="handleMarkerDrag"
                  />
                </GMapMap>

              <div class="field mt-2">
                <div class="control has-icons-left">
                  <textarea
                    class="textarea is-large"
                    :class="{ 'is-danger': v$.form.message.$invalid, 'is-success': !v$.form.message.$invalid }"
                    placeholder="Nachricht"
                    v-model="v$.form.message.$model"
                    
                  ></textarea>
                  <span class="icon is-large is-left">
                    <i class="fas fa-comment"></i>
                  </span>
                </div>
                <p
                  v-for="error of v$.form.message.$errors"
                  :key="error.$uid"
                  class="help is-danger has-text-left"
                >                
                {{ error.$message }}
                </p>
              </div>

              <div class="file is-boxed is-fullwidth">
                <label class="file-label">
                  <input
                    class="file-input"
                    type="file"
                    accept="image/*"
                    @change="selectImage($event)"
                    multiple
                  />
                  <span class="file-cta">
                    <span class="file-icon">
                      <i class="fas fa-upload"></i>
                    </span>
                    <span class="file-label"> Bilder auswählen... </span>
                  </span>
                </label>
              </div>

              
              <div class="columns">
                <div v-for="(item, index) in preview_list" :key="index">
                  <div class="column">
                    <figure class="image is-128x128">
                      <button
                        class="delete"
                        @click.prevent="removeItem(index)"
                      ></button>
                      <img :src="item" />
                    </figure>
                  </div>
                </div>
              </div>
              

              <div class="field">
                <div class="control">
                  <button
                    class="button is-large is-primary is-fullwidth  mt-5"
                    @click.prevent="showModal"
                    :disabled="v$.form.type.$invalid || v$.form.place.$invalid || v$.form.message.$invalid"
                  >
                    Verifikation
                  </button>
                </div>
              </div>
            </form>

            
            <div v-if="success">
              <div class="notification is-success">
                <h1 class="title">Vielen Dank!</h1>
                <p>Ihre Nachricht wurde erfolgreich versendet.</p>
              </div>
              <div class="field mt-2">
                <div class="control">
                  <button
                    class="button is-large is-primary is-fullwidth mt-2"
                    @click.prevent="reloadPage"
                  >
                    Schliessen
                  </button>
                </div>
              </div>
            </div>
            

            <Transition name="bounce"> 
            <div v-if="error">
              <div class="notification is-danger">
                <h1 class="title">Fehler!</h1>
                <p>Ihre Nachricht konnte nicht versendet werden.</p>
              </div>
              <div class="field mt-2">
                <div class="control">
                  <button
                    class="button is-large is-primary is-fullwidth mt-2"
                    @click.prevent="reloadPage"
                  >
                    Schliessen
                  </button>
                </div>
              </div>
            </div>
            </Transition> 

            <Transition name="bounce">
              <div v-if="showModalFlag && !sendVerificationMessage">
                <div class="notification is-warning is-light">
                  <h1 class="title">Verifikation</h1>                
                  <p>Bitte geben Sie zur Verifikation ihre Mobilenummer an. Sie werden anschliessend eine SMS mit einem Code erhalten.</p>
                </div>

                <div class="field mt-2">
                  <div class="control has-icons-left">                    
                      <input class="input is-large" type="text" placeholder="Name" v-model="form.name" />
                    <span class="icon is-large is-left">
                      <i class="fas fa-user"></i>
                    </span>
                  </div>
                </div>
                <div class="field mt-2">
                  <div class="control has-icons-left">                    
                      <input class="input is-large" type="tel" placeholder="Mobilenummer" v-model="v$.form.phone.$model" :class="{ 'is-danger': v$.form.phone.$invalid, 'is-success': !v$.form.phone.$invalid }"/>
                    <span class="icon is-large is-left">
                      <i class="fas fa-mobile-alt"></i>
                    </span>
                  </div>
                 <p
                  v-for="error of v$.form.phone.$errors"
                  :key="error.$uid"
                  class="help is-danger has-text-left"
                >                
                {{ error.$message }}
                </p>

                <div class="field mt-2 ">
                  <label class="checkbox is-large">
                  <input type="checkbox" v-model="form.contactMe">
                  Wir dürfen Sie bei Fragen oder Abschluss des Falles kontaktieren. (SMS)
                </label>
                </div>


                    <button
                      class="button is-large is-primary is-fullwidth mt-2"
                      @click.prevent="sendVerification"
                      :disabled="v$.form.phone.$invalid"
                    >
                      Code anfordern
                    </button>                
                </div>
              </div>
              </Transition>
             
            <div v-if="showModalFlag && sendVerificationMessage">
              <div class="notification is-warning is-light">
                <h1 class="title">Verifikation</h1>                
                <p v-if="checkVerificationMessage">Bitte Code Eingeben</p>
                <p v-if="!checkVerificationMessage" class="has-text-danger">Code falsch - bitte erneut eingeben</p>
              </div>
              <div class="field mt-2">
                <div class="control has-icons-left">
                  <input class="input is-large" type="tel" placeholder="Code" v-model="v$.form.code.$model" :class="{ 'is-danger': v$.form.code.$invalid, 'is-success': !v$.form.code.$invalid }"/>
                  <span class="icon is-large is-left">
                    <i class="fas fa-key"></i>
                  </span>
                </div>
                <p
                  v-for="error of v$.form.code.$errors"
                  :key="error.$uid"
                  class="help is-danger has-text-left"
                >                
                {{ error.$message }}
                </p>
                  <button
                    class="button is-large is-primary is-fullwidth mt-2"
                     @click.prevent="checkVerification"
                     :disabled="v$.form.code.$invalid"
                  >
                    Formular absenden
                  </button>                
              </div>
            </div>
            


          </div>
        </div>
      </div>
    </div>
  </div>




</template>
<script>
import axios from "axios";
import useVuelidate from '@vuelidate/core'
import { required, minLength, numeric } from '@vuelidate/validators'


export default {
  setup () {
    return {
      v$: useVuelidate()
    }
  },

  name: "PostFormAxios",
  data() {
    return {
      files: [],
      form: {
        place: "",
        type: "",
        message: "",
        phone: "",
        code: "",
        name: "",
        contactMe: false
      },
      preview_list: [],
      image_list: [],
      error: false,
      success: false,
      hasFiles: false,
      ticketProcesssed: false,
      errorMessage: "",
      marker: { position: { lat: 47.35013, lng: 7.90725 } },
      center: { lat: 47.35013, lng: 7.90725 },
      address: "",
      showModalFlag: false,
      sendVerificationMessage: false,
      checkVerificationMessage: true,
      phone: ""
    };
  },
  methods: {
    
    showModal() {
      //this.okPressed = false;
      this.showModalFlag = true;
    },

  cancelModal() {
      //this.okPressed = false;
      this.showModalFlag = false;
    },

    previewMultiImage(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          };
          this.image_list.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index++;
        }
      }
    },

    selectImage(event) {
      this.files = event.target.files;
      this.previewMultiImage(event);
    },
    submitForm() {
      console.log(this.form);
      this.showModalFlag = false;
      axios
        .post(process.env.VUE_APP_API_URL + "/ticket/5", this.form)
        .then((res) => {
          //Perform Success Action
          console.log(res.data);
          this.ticketProcesssed = true;
          this.checkForFiles(res.data);
          this.successSend();
        })
        .catch((error) => {
          // error.response.status Check status code
          this.errorHappend(error);
          console.log(error);
        })
        .finally(() => {
          //Perform action in always
          console.log("finished");
        });
    },
    checkForFiles(id) {
      if (this.files) {
        this.hasFiles = true;
        Object.keys(this.files).forEach((key) => {
          console.log(this.files[key]);
          const formData = new FormData();
          formData.append("file", this.files[key]);
          formData.append("filename", this.files[key].name);
          console.log(this.files[key].name);
          axios
            .post(process.env.VUE_APP_API_URL + "/upload/" + id, formData)
            .then((res) => {
              //Perform Success Action
              console.log(res);
            })
            .catch((error) => {
              // error.response.status Check status code
              console.log(error);
            })
            .finally(() => {
              //Perform action in always
              console.log("finished");
            });
        });
      }
    },

    errorHappend(error) {
      this.error = true;
      this.success = false;
      this.errorMessage = error.response.data.message;
    },

    successSend() {
      this.error = false;
      this.success = true;
    },

    reloadPage() {
      window.location.reload();
    },

    removeItem(index) {
      console.log(index);
      this.preview_list.splice(index, 1);
    },

    //SMS Verification
    sendVerification() {
      
      if (this.form.phone.length == 10) {
        this.phone = "+41" + this.form.phone.substring(1);        
      } else {
        this.phone = this.form.phone;
      }
      
      axios
        .post("https://verify-gvme-9136-dev.twil.io/start-verify", {
          channel: "sms",
          to: this.phone,
        })
        .then((res) => {
          //Perform Success Action
          this.sendVerificationMessage = res.data.success;
          console.log(res.data);          
        })
        .catch((error) => {
          // error.response.status Check status code
          console.log(error);
        })
        .finally(() => {
          //Perform action in always
          console.log("finished");
        });
    },

     //SMS Verification
    checkVerification() {
      axios
        .post("https://verify-gvme-9136-dev.twil.io/check-verify", {
          channel: "sms",
          to: this.phone,
          code: this.form.code,
        })
        .then((res) => {
          //Perform Success Action
          console.log(res.data);
          if (res.data.success) {            
            this.submitForm();
          } else {
            this.checkVerificationMessage = res.data.success;
          }          
                    
        })
        .catch((error) => {
          // error.response.status Check status code
          console.log(error);
        })
        .finally(() => {
          //Perform action in always
          console.log("finished");
        });
    },



    //detects location from browser
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.reverseGeocode(this.marker.position);
      });
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      //console.log(e);
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      console.log(this.marker.position);
      this.reverseGeocode(this.marker.position);
    },

    getAddressData(addressData) {
      this.address = addressData;
      this.marker.position = {
        lat: addressData.geometry.location.lat(),
        lng: addressData.geometry.location.lng(),
      };
    },

    reverseGeocode(coordinates) {
      axios
        .get(
          `https://maps.google.com/maps/api/geocode/json?latlng=${coordinates.lat},${coordinates.lng}&key=AIzaSyDHehMa-XxzUxWjJAH9MYTOi8BSIza-itI`
        )
        .then((response) => {
          console.log(response.data.results[0].formatted_address);

          this.$refs.gmapAutocomplete.$refs.input.value = response.data.results[0].formatted_address;
          this.form.place = response.data.results[0].formatted_address;
          //this.$refs.gmapAutocomplete.$refs.input.dispatchEvent(new Event('input'));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.geolocate();
    
  },
  validations() {
    return {
      form: {        
        phone: {
            required, 
            min: minLength(10),
            numeric,
        },
        code: {
            required,             
        },
        place: {
            required,             
        },
        type: {
            required,             
        },
        message: {
            required,             
        },
      },
    }
  },
   

};
</script>
